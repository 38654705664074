!function() {
  UC.modals.EditMainAttorneyProfileDetails = Backbone.View.extend({
    el: '#mdlEditMain',

    events: {
      'change .barType': 'updateBarType',
      'click .js-lnkRemoveBarCert': 'removeBarCert',
      'click #lnkAddBarCert': 'addBarCert',
      'click #btnSaveMain': 'saveModalForm',
    },

    initialize: function() {
      this.datepickerOptions = {
        dateFormat: 'mm/dd/yy',
        changeMonth: true,
        changeYear: true,
        yearRange: "1940:+nn",
        maxDate: "0"
      };
      this.$btnLoader = this.$el.find('#btnSaveMain').buttonLoader();
      this.$txtTagline = this.$el.find('#txtTagline');
      this.$txtServicesRate = this.$el.find('#txtServicesRate');
      this.$selHasInsurance = this.$el.find('#selHasInsurance');
      this.$txtLawSchool = this.$el.find('#txtLawSchool');
      this.$tblBarCerts = this.$el.find('#tblBarCerts');

      this.storeInitialValues();

      this.$tblBarCerts.find('.glyphicon.glyphicon-question-sign').tooltip();
      this.$selHasInsurance.select2({width: '100%'});
      this.showBarType($('#barCertTemplate div.bar-row'));

      this.$el.find("select[name='set_law_school']").superSmartSearch({
        url: '/schools/smart_search',
        width: '100%',
        allowClear: true,
        tags: true
      });
    },

    storeInitialValues: function() {
      const tagLine = this.$txtTagline.val();
      this.initialData = {
        tagLine: tagLine ? tagLine.trim() : null,
        lawSchool: this.$txtLawSchool.val(),
        servicesRate: this.$txtServicesRate.val(),
        haveInsurance: this.$selHasInsurance.val(),
        barCerts: this.collectBarCerts()
      };
    },

    openModal: function(e) {
      if (e) e.preventDefault();

      UC.utils.clearValidationErrors('has-error', this.$el);

      this.rowId = UC.constants.bars_count || 0;

      this.$txtTagline.val(this.initialData.tagLine);
      this.$txtServicesRate.val(this.initialData.servicesRate);
      this.$selHasInsurance.val(this.initialData.haveInsurance).trigger('change');
      this.$txtLawSchool.val(this.initialData.lawSchool).trigger('change');
      this.$tblBarCerts.find('.bar-row').remove();

      if (this.initialData.barCerts.length == 0) {
        this.addBarCert();
      } else {
        _.each(this.initialData.barCerts, function(barCert) {
          this.addBarCert(null, barCert);
        }.bind(this));
      }

      this.$el.modal({ backdrop: 'static' });
    },

    removeBarCert: function(e) {
      e.preventDefault();

      $(e.currentTarget).parents('.bar-row').eq(0).remove();
    },

    updateBarType: function(e) {
      this.showBarType($(e.target).closest('.bar-row'));
    },

    showBarType: function($row) {
      var barCert = $row.attr('barCert'),
          selBarType = $row.find(".barType[barCert='" + barCert + "']"),
          txtBarCert = $row.find(".barCert[barCert='" + barCert + "']");

      var barType = selBarType.val() && selBarType.val().toLowerCase();

      if (barType == "state") {
        $row.find('.cert .barStateWrapper').show();
        $row.find('.cert .barCert').hide();
      }
      else if (barType == "patent") {
        $row.find('.cert .barStateWrapper').hide();
        $row.find('.cert .barCert').show();

        txtBarCert.val("Patent Bar");
        txtBarCert.prop('disabled', true);
      }
      else if (barType == "other") {
        $row.find('.cert .barStateWrapper').hide();
        $row.find('.cert .barCert').show();

        txtBarCert.prop('disabled', false);
      }
    },

    barCertValue: function(raw) {
      return raw.charAt(0).toUpperCase() + raw.substring(1).toLowerCase();
    },

    addBarCert: function(e, values) {
      if (e) e.preventDefault();

      var newRow = this.createBarCertRow();

      if (values) {
        newRow.find('.barAdmittedDate').val(values.bar_admitted_date);
        newRow.find('.barNumber').val(values.bar_number);
        newRow.find('.barType').val(this.barCertValue(values.bar_type)).trigger('change');
        newRow.find('.barState').val(values.bar_state).trigger('change');
        newRow.find('.barCert').val(values.bar_cert_name).trigger('change');
      }

      this.$el.find('#tblBarCertsFooter').before(newRow);
      this.$el.find(".barType[barCert='" + this.rowId + "']").change(this.updateBarType.bind(this));
      this.$el.find(".js-lnkRemoveBarCert[barCert='" + this.rowId + "']").click(this.removeBarCert.bind(this));

      newRow.find('.datepicker').datepicker(this.datepickerOptions);

      this.showBarType(newRow);

      this.rowId++;
    },

    createBarCertRow: function() {
      var template = $('#barCertTemplate div.bar-row').clone();

      template.attr('barCert', this.rowId);
      template.find('*').each( function(index, element) {
        if ($(element).attr('barCert') === 'temp') {
          $(element).attr('barCert', this.rowId);
        }
      }.bind(this));

      template.find('select').select2({width: '100%'});

      return template;
    },

    saveModalForm: function(e) {
      var data = {
        Tagline: this.$txtTagline.val().trim(),
        set_law_school: this.$txtLawSchool.val(),
        ServicesRate: this.$txtServicesRate.val(),
        HaveInsurance: this.$selHasInsurance.val(),
        BarCerts: this.collectBarCerts()
      };

      if (this.validateMain(data)) {
        this.$btnLoader.showLoader();
        UC.net.post(UC.constants.updateMainPath, data, this.onUpdateMain.bind(this)).
        always(function() {
          this.$btnLoader.hideLoader();
        }.bind(this));
      };
    },

    onUpdateMain: function(response) {
      this.storeInitialValues();

      if (UC.action === 'index' && UC.controller === 'dashboard') {
        UC.utils.checklistSuccessCallback('btnEditMain');
      } else {
        $('#mainContent').html(response.html);
        $('#stats').html(response.stats_html);
      };

      this.$el.modal('hide');
    },

    collectBarCerts: function() {
      var barCerts = [];

      this.$el.find("div.bar-row").each(function () {
        var barCert = {}, $this = $(this);

        if ($this.attr('barcert') !== 'temp') {
          barCert.bar_type = $this.find(".barType").val().toLowerCase();
          barCert.bar_state = $this.find(".barState").val();
          barCert.bar_cert_name = $this.find(".barCert").val();
          barCert.bar_number = $this.find(".barNumber").val();
          barCert.bar_admitted_date = $this.find(".barAdmittedDate").val();
          barCert.element = $this;
          barCerts.push(barCert);
        }
      });
      return barCerts;
    },

    validateMain: function(data) {
      UC.utils.clearValidationErrors('has-error', this.$el);
      var errors = [];

      if (!data.set_law_school) {
        errors.push({
          element: this.$txtLawSchool,
          text: 'Law School is required.',
          className: 'form-group'
        })
      }

      if (!data.ServicesRate || data.ServicesRate <= 0) {
        errors.push({
          element: this.$txtServicesRate,
          text: "Hourly Rate must be a number greater than 0",
          className: 'form-group'
        });
      }

      if (!data.HaveInsurance) {
        errors.push({
          element: this.$selHasInsurance,
          text: 'Selection is required',
          className: 'form-group'
        });
      }

      if (data.BarCerts.length == 0) {
        errors.push({
          element: this.$el.find('#tblBarCertsFooter'),
          text: 'Please provide at least one bar',
          className: 'form-group'
        });
      }

      $(data.BarCerts).each(function(){
        if (!this.bar_number) {
          errors.push({
            text: 'Number required',
            element: this.element.find('.barNumber')
          });
        };

        if (this.bar_type == 'other' && this.bar_cert_name == '') {
          errors.push({
            text: 'Bar required',
            element: this.element.find('.barCert')
          });
        };

        var dateFormatted = moment(this.bar_admitted_date, UC.constants.momentDateFormats, true);
        if (!this.bar_admitted_date) {
          errors.push({
            text: 'Date required',
            element: this.element.find('.barAdmittedDate'),
            className: 'bar-input'
          });
        } else if (!dateFormatted.isValid()){
          errors.push({
            element: this.element.find('.barAdmittedDate'),
            text: "(mm/dd/yyyy)",
            className: 'bar-input'
          });
        } else if (moment().isBefore(dateFormatted)) {
          errors.push({
            element: this.element.find('.barAdmittedDate'),
            text: "Future date",
            className: 'bar-input'
          });
        };

        delete this.element
      });

      if (errors.length > 0) {
        UC.utils.showValidationErrors(errors);
        return false;
      }

      return true;
    }
  });
}();
